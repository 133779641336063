@import 'material.scss';
@import 'variables.scss';
@import 'node_modules/bootstrap/scss/bootstrap';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.fa-solid {
  color: #7e9e99;
}

@font-face {
  font-family: 'Lobster';
  src: url('assets/fonts/Lobster.otf') format('opentype');
}

.brand-logo {
  flex-direction: column;

  h1 {
    color: #7e9e99;
    font-weight: 300;
    font-size: 39px;
    line-height: 0.65;
    font-family: 'Lobster', cursive;
  }
  p {
    font-size: 14px;
    margin-left: 30px;
    margin-bottom: 0;
    color: #545454;
    font-weight: 400;
    text-transform: capitalize;
  }
}
